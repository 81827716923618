<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <transition-group name="fadeInUp" mode="in-out">
      <!-- Start Main Loader -->
      <MainLoader key="loader" v-if="loading" />
      <!-- End Main Loader -->

      <div key="main" v-else>
        <!-- Start Statistics Card-->
        <div class="order-details-page mb-5">
          <div class="col-md-6 mx-auto">
            <div class="card-table">
              <div class="image">
                <img
                  class="img-fluid"
                  :src="allData.image"
                  :alt="allData.image"
                />
              </div>

              <div class="logo-hidden">
                <img src="@/assets/images/icons/logo.png" alt="" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-4 col-xxl-3">
              <div class="wrraper">
                <stats-card
                  v-if="allData.applly_coupon_on == 'special_products'"
                  title="يطبق علي"
                  :number="'منتجات مخصصصة'"
                ></stats-card>
                <stats-card
                  v-else-if="allData.applly_coupon_on == 'special_categories'"
                  title="يطبق علي"
                  :number="'اقصام مخصصصة'"
                ></stats-card>
                <stats-card
                  v-else-if="allData.applly_coupon_on == 'except_products'"
                  title="يطبق علي"
                  :number="'باستثناء منتجات معينه'"
                ></stats-card>
                <stats-card
                  v-else-if="allData.applly_coupon_on == 'except_categories'"
                  title="يطبق علي"
                  :number="'باستثناء اقسام معينه'"
                ></stats-card>
                <stats-card
                  v-else
                  title="يطبق علي"
                  :number="'الكل'"
                ></stats-card>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-xxl-3">
              <div class="wrraper">
                <stats-card
                  title="كود الكوبون"
                  :number="allData.code"
                ></stats-card>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-xxl-3">
              <div class="wrraper">
                <stats-card
                  title="نوع الخصم"
                  :number="
                    allData.discount_type == 'percentage' ? 'نسبة' : 'كمية'
                  "
                ></stats-card>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-xxl-3">
              <div class="wrraper">
                <stats-card
                  title="كمية الخصم"
                  :number="allData.discount_amount"
                ></stats-card>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="wrraper">
                <stats-card
                  title="اقصي قيمة للخصم"
                  :number="allData.max_discount"
                ></stats-card>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="wrraper">
                <stats-card
                  title="اقصي عدد للاستخدام"
                  :number="allData.max_used_num"
                ></stats-card>
              </div>
            </div>

            <div class="col-sm-6 col-md-4">
              <div class="wrraper">
                <stats-card
                  title="عدد استخدام لليوزر الواحد"
                  :number="allData.max_used_for_user"
                ></stats-card>
              </div>
            </div>

            <div class="col-sm-6 col-md-4">
              <div class="wrraper">
                <stats-card
                  title="تاريخ البدء"
                  :number="`${allData.start_time} - ${allData.start_at} `"
                ></stats-card>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="wrraper">
                <stats-card
                  title="تاريخ الانتهاء"
                  :number="`${allData.end_time} - ${allData.end_at} `"
                ></stats-card>
              </div>
            </div>
          </div>
          <hr class="line" />
          <div class="row">
            <h2 class="title mx-2">تفاصيل الاستخدام</h2>

            <div class="col-sm-6 col-xl-4">
              <div class="wrraper">
                <stats-card
                  title="عدد مرات الاستخدام"
                  :number="allData.num_of_used"
                ></stats-card>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="wrraper">
                <stats-card
                  title="مرات الاستخدام المتبقية"
                  :number="allData.remain_used"
                ></stats-card>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="wrraper">
                <stats-card
                  title="عدد طلبات استخدام الكوبون"
                  :number="allData.order_count_use_coupon"
                ></stats-card>
              </div>
            </div>
          </div>

          <hr class="line" />
          <div class="product-details">
            <div class="row">
              <h2 class="title">
                طلبات استخدام الكوبون
                <v-icon class="show" small @click="getCouponOrders">
                  fal fa-eye
                </v-icon>
              </h2>
              <div class="col-12" v-if="couponOrders.length">
                <v-data-table
                  class="elevation-1 thumb diff_table"
                  :headers="headers"
                  :items="couponOrders"
                  :loading="btnIsLoading"
                  :loading-text="$t('table.loadingData')"
                  item-key="id"
                >
                  <!-- ================== You Can use any slots you want ================== -->
                  <!-- Select no data State -->
                  <template v-slot:no-data>
                    {{ $t("table.noData", { name: "بروموكود" }) }}
                  </template>

                  <template v-slot:[`item.index`]="{ index }">
                    {{ index + 1 }}
                  </template>

                  <template v-slot:[`item.is_payment`]="{ item }">
                    <span
                      class="status"
                      :class="item.is_payment ? 'paid' : 'unpaid'"
                    >
                      {{ item.is_payment ? "مدفوع" : "غير مدفوع" }}
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <div class="action">
                      <div
                        v-if="
                          item.status == 'admin_cancel' ||
                          item.status == 'client_cancel' ||
                          item.status == 'admin_delivered'
                        "
                      >
                        <span
                          v-if="
                            item.status == 'admin_cancel' ||
                            item.status == 'client_cancel'
                          "
                          class="status canceled"
                        >
                          {{ item.status_trans }}
                        </span>
                        <span
                          v-if="item.status == 'admin_delivered'"
                          class="status success"
                        >
                          {{ item.status_trans }}
                        </span>
                      </div>
                      <div class="select-wrapper" v-else>
                        <div class="input_wrapper">
                          <span class="status pending">
                            {{ item.status_trans }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-slot:[`item.to`]="{ item }">
                    <router-link :to="`/users/show/${item.to.id}`">
                      {{ item.to.fullname }}
                    </router-link>
                  </template>
                  <!-- End:: Users Routes -->

                  <!-- Start:: Report Reason Modal Button -->
                  <template v-slot:[`item.report_reason`]="{ item }">
                    <v-icon
                      class="show"
                      small
                      @click="controlReportReasonModalModal(item.report_reason)"
                      v-if="item.report_reason"
                    >
                      fal fa-eye
                    </v-icon>

                    <span v-else>_</span>
                  </template>
                  <!-- End:: Report Reason Modal Button -->

                  <!-- Select actions-->
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="_actions">
                      <v-icon class="show" small @click="showItem(item)">
                        fal fa-eye
                      </v-icon>
                      <!-- <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon> -->
                      <!-- <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon> -->
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
        <base-model
          @closeModel="model_1.show_model = false"
          :show="model_1.show_model"
        >
          <div class="image">
            <img
              v-if="model_1.model_img_src"
              :src="model_1.model_img_src"
              alt="..."
            />
          </div>
        </base-model>
        <!-- End Statistics Card-->
        <!-- Start:: Text Content Modal -->
        <DeleteModel
          :id="allData.id"
          @toggleModal="toggleDeleteModal"
          :modalIsActive="deleteModalIsOpen"
          :elementIdToGet="deleteItemToShow"
        />
        <!-- End:: Text Content Modal -->
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Start:: Importing Modals
import DeleteModel from "@/components/Modals/DeleteModel.vue";
// End:: Importing Modals

export default {
  props: ["id"],
  name: "Withdraw",

  components: {
    DeleteModel,
  },

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.orders.title"),
          disabled: true,
          href: "",
        },

        // {
        //   text: this.$t("breadcrumb.transaction.products"),
        //   disabled: true,
        //   href: "",
        // },
      ],

      // ========== Statistics
      statisticsItem: {},
      rows: null,

      // ========== Top Section
      search: "",
      model_1: {
        show_model: false,
        model_img_src: "",
      },
      rejectedReason: "",

      deleteModalIsOpen: false,
      deleteItemToShow: null,

      // ========== Your Data
      allData: null, // injected in created
      couponOrders: [],
      // ========== Loading
      loading: false,
      reportReasonToShow: null,
      btnIsLoading: false,
      e1: 1,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),
    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            sortable: true,
            width: "80",
          },
          {
            text: "رقم الطلب",
            align: "center",
            value: "id",
            sortable: false,
          },
          {
            text: "اجمالي السعر",
            align: "center",
            value: "total_price",
            sortable: false,
          },
          {
            text: "اجمالي كمية المنتج",
            align: "center",
            value: "total_quantity_product",
            sortable: false,
          },
          {
            text: "حالة الدفع ؟",
            align: "center",
            value: "is_payment",
            sortable: false,
          },
          {
            text: "الحالة",
            align: "center",
            value: `status`,
            sortable: false,
            width: "200",
            padding: "2",
          },

          {
            text: "التاريخ",
            align: "center",
            value: "created_at",
            sortable: false,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "id",
            sortable: true,
          },
          {
            text: "Reporter",
            align: "center",
            value: "from",
            sortable: false,
          },
          {
            text: "Reported",
            align: "center",
            value: "to",
            sortable: false,
          },
          {
            text: "Report Reason",
            align: "center",
            value: "report_reason",
            sortable: false,
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },

    orderStatusItems() {
      return [
        {
          label: "انتظار قبول الادارة",
          name: "pending",
        },
        {
          label: "قبول من قبل الادارة",
          name: "admin_accept",
        },
        {
          label: "الالغاء من قبل الادارة",
          name: "admin_cancel",
        },
        {
          label: "الغاء من قبل العميل",
          name: "client_cancel",
        },
        {
          label: "جاري الشحن",
          name: "admin_shipping",
        },
        {
          label: "تم التوصيل",
          name: "admin_delivered",
        },
        {
          label: "الانتهاء للعميل",
          name: "client_finished",
        },
      ];
    },
  },

  methods: {
    changeStatus(currStatus, id) {
      this.btnIsLoading = true;
      let submit_data = new FormData();
      submit_data.append("_method", "PUT");
      submit_data.append("status", currStatus);
      this.$axios({
        method: "POST",
        url: `order/${id}/change_status`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("sendSuccess"),
            position: "bottomRight",
          });
          // this.$router.push({ path: "/transactions/all-transactions" });
          this.btnIsLoading = false;
          this.setRows();
        })
        .catch((err) => {
          if (err.response.data.status == "fail") {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          }

          this.setRows();
          this.btnIsLoading = false;
        });
    },
    orderStatusList(status) {
      if (status == "pending") {
        return [
          {
            text: "قبول",
            value: "admin_accept",
          },
          { text: "رفض", value: "admin_rejected" },
        ];
      } else if (status == "admin_accept") {
        return [
          { text: "شحن", value: "admin_shipping" },
          { text: "الغاء", value: "admin_cancel" },
        ];
      } else if (status == "admin_rejected") {
        return [{ text: "الغاء", value: "admin_cancel" }];
      } else if (status == "admin_shipping") {
        return [
          { text: "تم التوصيل", value: "admin_delivered" },
          { text: "الغاء", value: "admin_cancel" },
        ];
      } else if (status == "admin_delivered") {
        return [{ text: "الغاء", value: "admin_cancel" }];
      }
    },
    // ===== Toggle Delete Modal =====
    toggleDeleteModal(item) {
      this.deleteItemToShow = item;
      this.deleteModalIsOpen = !this.deleteModalIsOpen;
    },

    // Set Rows
    setRows() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "coupon/" + this.id,
      })
        .then((res) => {
          this.allData = res.data.data;
          this.rows = res.data.data.products;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    getCouponOrders() {
      this.btnIsLoading = true;
      this.$axios({
        method: "GET",
        url: `coupon_addition_data/${this.id}/orders`,
      })
        .then((res) => {
          this.couponOrders = res.data.data;

          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.btnIsLoading = false;
        });
    },
    //Change Status
    changeStatus(currStatus) {
      this.btnIsLoading = true;
      let submit_data = new FormData();
      submit_data.append("_method", "PUT");
      submit_data.append("status", currStatus);
      this.$axios({
        method: "POST",
        url: `order/${this.allData.id}/change_status`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("sendSuccess"),
            position: "bottomRight",
          });
          // this.$router.push({ path: "/transactions/all-transactions" });
          this.btnIsLoading = false;
          this.setRows();
        })
        .catch((err) => {
          // console.log(err)
          if (err.response.data.status == "fail") {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          }

          this.setRows();
          this.btnIsLoading = false;
        });
    },

    showItem(item) {
      this.$router.push({ path: "/orders/show/" + item.id });
    },

    orderStatus(status) {
      let message = "";
      switch (status) {
        case "pending":
          message = "جاري التنفيذ";
          break;
        case "admin_accept ":
          message = "تم القبول";

          break;
        case "admin_shipping":
          message = "جاري الشحن";

          break;
        case "admin_delivered":
          message = "تم التوصيل";

          break;
        case "admin_rejected":
          message = "تم الرفض ";

          break;
        case "admin_cancel":
          message = "تم الالغاء";

          break;
        default:
      }
      return message;
    },
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },
  },
  created() {
    this.setRows();
  },

  // ======= hooks
};
</script>

<style lang="scss">
.order-details-page {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 11%);
  padding: 20px 0;
  h2.title {
    font-size: 14px !important;
    padding: 0 10px;
    font-weight: bold !important;
    text-align: start !important;
  }
  span.status {
    font-size: 14px;
    &.finished {
      color: #02bc77;
    }
    &.pendding {
      color: #ffc800;
    }
    &.shipping {
      color: #a900ff;
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px;
  }
  .action {
    .v-input {
      padding: 0;
      margin: 0;
    }
    .v-input__control {
      background: #e7e7e7;
      padding: 8px;
      max-width: 200px;
      border-radius: 5px;
    }
    .v-text-field__details {
      display: none !important;
    }
    .v-input__slot {
      margin: 0;
      &::before {
        content: unset !important;
      }
    }
    .v-select__slot {
      &::after {
        content: unset !important;
      }
    }
    input[role="readonly"] {
      display: none !important;
    }
  }
  .details {
    padding: 0 10px;
    h5.title {
      color: #000;
      font-size: 14px !important;
      font-weight: bold;
      margin-bottom: 0%;
    }
    .item {
      display: flex;
      align-items: flex-start;
      .icon {
        background: #f5f5fa;
        border-radius: 50%;
        width: 50px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px !important;
        * {
          color: #000;
        }
      }
      .info {
        flex: 1;
        margin-inline-start: 8px;
        background: none !important;
        p {
          font-size: 14px;
          color: #8d8d8d;
          margin-bottom: 0;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  .product-details {
    padding: 0 10px;
    h2.title {
      font-size: 18px !important;
    }
  }
  table {
    width: 100%;
    td.title {
      font-size: 16px !important;
      line-height: 1.7;
      &.status {
        font-size: 14px !important;
        padding: 0;
      }
    }
    td.value {
      font-size: 14px !important;
      font-weight: bold;
      text-align: end;

      span.status {
        justify-content: flex-end;
        padding: 0;
      }
    }
  }

  div.line {
    padding: 10px 0;
  }
  tfoot {
    border-top-width: 1px;
    margin-top: 10px;
  }
}

.btns_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 5px;
  }
}
</style>
